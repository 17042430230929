@import 'cssVars';
@import './Vars';

.iso__custom__modal-open {
  overflow: hidden !important;
}

.iso__custom__modal-open .modal {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

#iso {
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/mixins';
  @import 'IBE/functions-override';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/root';
  @import '~bootstrap/scss/reboot';
  @import '~bootstrap/scss/type';
  @import '~bootstrap/scss/images';
  @import '~bootstrap/scss/code';
  @import '~bootstrap/scss/grid';
  @import '~bootstrap/scss/tables';
  @import '~bootstrap/scss/forms';
  @import '~bootstrap/scss/buttons';
  @import '~bootstrap/scss/transitions';
  @import '~bootstrap/scss/dropdown';
  @import '~bootstrap/scss/button-group';
  @import '~bootstrap/scss/input-group';
  @import '~bootstrap/scss/custom-forms';
  @import '~bootstrap/scss/nav';
  @import '~bootstrap/scss/navbar';
  @import '~bootstrap/scss/card';
  @import '~bootstrap/scss/breadcrumb';
  @import '~bootstrap/scss/pagination';
  @import '~bootstrap/scss/badge';
  @import '~bootstrap/scss/jumbotron';
  @import '~bootstrap/scss/alert';
  @import '~bootstrap/scss/progress';
  @import '~bootstrap/scss/media';
  @import '~bootstrap/scss/list-group';
  @import '~bootstrap/scss/close';
  @import '~bootstrap/scss/toasts';
  @import '~bootstrap/scss/modal';
  @import '~bootstrap/scss/tooltip';
  @import '~bootstrap/scss/popover';
  @import '~bootstrap/scss/carousel';
  @import '~bootstrap/scss/spinners';
  @import '~bootstrap/scss/utilities';
  @import '~@ibe/components/dist/Styles/stylesNoBootstrap';
  @import 'IBE/Forms';
  @import 'IBE/PackageDetails';
  @import 'IBE/productTeaser';
  @import 'IBE/CheckoutForms';
  @import 'IBE/CarouselComponent';
  @import 'IBE/details';
  @import 'IBE/confirmation';
  @import 'IBE/FlightSearchResult';
  @import 'IBE/packageSearchContent';
  @import 'IBE/StickySummary';
  @import 'IBE/PriceOverviewHeader';
  @import 'IBE/search';
  @import 'IBE/listItem';
  @import 'IBE/productibe';
  @import 'IBE/SimpleList';
  @import 'IBE/OptionalOffer';
  @import 'IBE/promCode';
  @import 'IBE/loyaltyCard';
  @import 'IBE/insurance';

  --light: #f8f9fa;

  // custom overrides
  $accommodation-adapt-overlay-size: 0.8;

  .row {
    margin-left: -0.3125 * $font-size-base !important;
    margin-right: -0.3125 * $font-size-base !important;
  }

  .container,
  .col,
  [class*='col-']:not([class*='col-auto']) {
    @media (min-width: 1200px) {
      padding-left: 0.3125 * $font-size-base !important;
      padding-right: 0.3125 * $font-size-base !important;
    }
  }

  &.iso__transparent {
    background-color: transparent;
  }

  .btn-outline-primary,
  .btn-secondary {
    border-radius: 5px !important;
  }

  .react-datepicker {
    font-size: $font-size-base * 0.8;

    &__year-select option {
      font-size: $font-size-base * 0.8;
    }
  }

  .iso__imageProgressBar__stepperItem__stepImage {
    z-index: 4;
  }

  .iso__imageProgressBar__stepperItem__stepName {
    line-height: 2;
  }

  button {
    font-weight: 400;
  }

  .btn {
    border-radius: $btn-border-radius;
    text-shadow: none;
  }

  label {
    color: $darkerBlack;
    font-weight: 400;
  }

  .search-form__label {
    font-size: 0.875 * $font-size-base;
  }

  @include small {
    .search-form__label {
      font-size: 1 * $font-size-base;
    }
  }

  .best-price__availability__btn.btn-primary {
    border-radius: 0 !important;
  }

  margin: 0; // 1
  font-family: $font-family-base;
  @include font-size($font-size-base);
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  text-align: left; // 3
  background-color: $body-bg; // 2
  color: $darkerBlack;

  a {
    color: $blue;
    text-decoration: none;

    &:hover {
      color: $darkblue;
    }
  }

  .packageGlobalErrorScroller {
    margin-top: 0.625 * $font-size-base;
  }

  .price {
    color: $orange;
  }

  .collapse-headline__separator::before {
    background-color: $orange;
  }

  .iso__service-search {
    background: $white;
    box-shadow: $defaultShadow;
    margin: 0;
    padding: 0.625 * $font-size-base;
    border-radius: $border-radius;

    label {
      font-size: $font-size-sm;
      margin-bottom: 0;
    }

    &__button {
      margin-top: 0.9375 * $font-size-base;

      @include large {
        margin-top: 0;
      }
    }

    .row:not(:first-child) {
      margin-top: 0.9375 * $font-size-base;
    }

    input,
    .select__value-container,
    .iso__dropdown__toggle,
    .iso__date-range-input {
      padding-left: 0;

      &:hover {
        box-shadow: none;
      }
    }

    .react-datepicker-wrapper button > div > div {
      left: initial;
    }

    .iso__date-range-input > div > div {
      justify-content: flex-start !important;

      > span {
        margin: 0 2.5px;
      }
    }

    &__icon {
      width: 20px !important;
    }

    .paxRoomSelection .select__dropdown-indicator > svg {
      color: $secondary;
    }
  }

  .iso__date-picker__popper {
    z-index: 1101;
  }

  // shadows
  .iso__three-column-list-item-layout,
  .iso__serviceList__filterContainer {
    box-shadow: $defaultShadow;
  }

  .iso__service-search .iso__date-range-input:hover {
    border-color: $blue !important;
  }

  // search
  .iso__date-range-input,
  #paxSelection .iso__dropdown__toggle {
    border-width: 0 0 2px;
    border-radius: 0;

    &:hover {
      background: $white;
      border-color: $blue;
    }
  }

  #paxSelection .list-group-item {
    border-right: 0;
    border-left: 0;
    border-top: 0;
  }

  // filter
  .iso__filterRange {
    &__track {
      background: $secondary;
      height: 4px;
      top: 11px;
    }

    &__thumb {
      background-color: $secondary;
      height: 24px;
      width: 24px;

      &::after {
        display: none;
      }
    }
  }

  .iso__baseLayoutFilter {
    &__headline {
      font-weight: 600;
    }

    .form-check {
      display: flex;
    }

    .form-check-label {
      display: inline-block;
      font-size: 14px;
      line-height: 24px;
    }

    &__caret {
      color: $secondary;
    }

    &__icon > svg.svg-inline--fa {
      display: none;
    }

    &__icon {
      align-items: flex-start;
    }

    &__inner label {
      display: inline-block;
    }
  }

  @media (min-width: 992px) {
    .iso__service-search > div.col-lg-3:last-child {
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    }
  }

  .btn-primary {
    color: $button-primary-color;
    background-color: $primary;
    border-radius: $btn-border-radius !important;
    border: 2px solid $primary;
    outline: 0;

    &:hover {
      border-color: $orange;
      color: $button-primary-color;
    }

    &:active,
    &:focus {
      outline: 0;
      box-shadow: none;
      border-color: $primary;
    }
  }

  .btn.btn-secondary {
    color: $secondary;
    border-radius: $btn-border-radius;
    background-color: $white;
    border: 2px solid $primary;
    outline: 0;

    &:hover {
      border-color: $orange;
      color: $secondary;
    }

    &:active,
    &:focus {
      outline: 0;
      box-shadow: none;
      border-color: $primary;
    }
  }

  .alert-warning,
  .alert-danger {
    background-color: $red;
    color: $white;
    border: none;
    min-height: 25px;
    padding: 0 0 0 5px;
  }

  .alert-success {
    background-color: $success;
    border-color: $success;
    color: $white;
  }

  .alert-info {
    background-color: $info;
    border-color: $info;
    color: $white;
  }

  .form-group.required label:after {
    color: $danger;
  }

  .iso__dropdown .btn {
    text-transform: none;
  }

  .iso__modal-dialog {
    max-width: 750px;
  }

  .react-datepicker {
    font-family: $font-family-base;
    border-radius: 0;

    &__month-container {
      padding: 0.625 * $font-size-base;
    }

    &__month {
      .react-datepicker__month-text {
        width: 3.125 * $font-size-base;
      }
    }

    &__day-name {
      margin: 0;
      line-height: 1.375 * $font-size-base;
      width: 38px;
      height: 38px;
      font-size: 14px;
      color: $gray-800;
    }

    &__day:hover {
      background-color: $gray-200;
      border-radius: 0;
    }

    &__day {
      margin-left: -1px;
      margin-bottom: -1px;
      margin-right: 0;
      margin-top: 0;
      border-radius: 0;
      line-height: 1.375 * $font-size-base;
      border: 1px solid $gray-200;
      width: 38px;
      height: 38px;

      &--today {
        background-color: white;
        color: $gray-800;
      }

      &--today:hover {
        background-color: $gray-300;
        color: white;
      }

      &--selecting-range-start:not(.react-datepicker__day--today) {
        background-color: $secondary !important;
      }

      &--selected,
      &--selecting-range-start,
      &--in-range,
      &--in-range:active {
        background-color: $secondary;
      }

      &--in-selecting-range:not(.react-datepicker__day--today),
      &--in-selecting-range:hover,
      &--keyboard-selected:not(.react-datepicker__day--today),
      &--keyboard-selected:active:not(.react-datepicker__day--today) {
        background-color: lighten($secondary, 10%);
      }

      &--in-range:hover:not(.react-datepicker__day--today) {
        background-color: lighten($secondary, 10%);
      }
    }

    &__current-month {
      font-size: 18px;
      color: #484848;
      font-weight: $font-weight-bold;
    }

    &__header {
      background: none;
      border-bottom: 0;

      &-button {
        border-width: 1px;
        border-color: $gray-300;
      }

      &-button:hover {
        border-width: 1px;
        border-color: $gray-300;
        background-color: $gray-200;
      }

      &-button:focus {
        box-shadow: none !important;
      }

      .btn:focus,
      .btn.focus {
        box-shadow: none !important;
      }

      .btn-outline-secondary:focus,
      .btn-outline-secondary.focus {
        box-shadow: none !important;
      }
    }
  }

  .iso__nav__item__heading-container {
    border-image-source: linear-gradient(to left, $gray-300 85%, $orange 15%);
  }

  .iso__nav__tabs-container {
    .nav-link:hover {
      background-color: transparent;
      border-bottom: 5px solid $gray-500;
    }

    &--transport {
      $nav-tabs-border-width: 4px;
      $nav-tabs-border-color: rgba($white, 0.1);
      color: $white;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      .nav-tabs {
        flex-wrap: nowrap;
        overflow-x: visible;
        border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;

        & .nav-item {
          margin: 0;
        }

        & .nav-link {
          border: $nav-tabs-border-width;

          &:hover {
            border-bottom: $nav-tabs-border-width solid $gray-500;
            margin-bottom: -$nav-tabs-border-width;
          }

          &.active,
          &.active:hover {
            color: $white;
            border-bottom: $nav-tabs-border-width solid $orange;
            margin-bottom: -$nav-tabs-border-width;
          }
        }
      }

      .tab-content {
        padding: 0;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .iso__nav__item__anchor-container {
    background-color: $blue;

    .btn {
      box-shadow: none;
      font-weight: $btn-font-weight;
    }

    .btn-primary {
      text-transform: capitalize;
      border: unset !important;
      box-shadow: none !important;
      background-color: unset !important;
    }
  }

  .iso__decorative-hr {
    display: block;
    width: 4.375 * $font-size-base;
    height: 4px;
    overflow: hidden;
    background-color: $orange;
  }

  .select__control,
  .iso__dropdown__toggle.btn,
  .iso__date-range-input.btn,
  .select__control {
    height: calc(1.5em + 2px + 0.3125rem);

    .select__indicators {
      height: calc(1.5em + 2px + 0.3125rem);
    }

    .select__indicator {
      padding: 5px;
    }
  }

  .divider {
    height: 1px;
    background-color: $gray-800;
    margin: 1.875 * $font-size-base 30px;
  }

  .info-badge {
    padding: 0.5 * $font-size-base 0.625 * $font-size-base;
    background-color: $beige;
    display: flex;

    svg {
      font-size: $font-size-base * 1.5;
      margin-right: 20px;
      position: relative;
      top: 1px;
      color: $secondary;
    }
  }

  .info-name-badge {
    padding: 0.5 * $font-size-base 0.625 * $font-size-base;
    background-color: #004f9e1a;
    display: flex;
    color: $secondary;

    svg {
      font-size: $font-size-base * 1.5;
      margin-right: 20px;
      position: relative;
      top: 1px;
      color: $secondary;
    }
  }

  .iso__imageProgressBar {
    overflow: hidden;
  }

  .confirmation-progress-bar {
    .iso__imageProgressBar__stepperItem__stepImage--completed {
      cursor: default;
    }
  }

  .flight-summary-header {
    border-bottom: 2px solid $lightblue;

    & > h3 {
      font-size: $font-size-base * 1.45;
      font-weight: 700;
    }
  }

  .iso__flight-summary-card,
  .iso__accommodation-board-summary-card {
    @include small {
      max-width: initial;
      margin: initial;
    }
  }

  .iso__accommodation-board-summary-card-body {
    margin-bottom: 15px;

    > div:first-child > div:first-child span {
      font-weight: 700;
    }
  }

  .iso__accommodation-board-summary-card-guest-icon {
    color: $gray-400;
  }

  .iso__flight-summary-card-body {
    .row > div:first-child {
      font-weight: 700;
    }

    .row > .col-2 {
      padding: 0;

      & > span {
        margin-right: 0.0625 * $font-size-base;
        font-size: 95%;
        white-space: nowrap;
      }
    }
  }

  .iso__summaryItem {
    &-subtitle {
      color: $primary !important;
      margin-bottom: 0.15625 * $font-size-base !important;
    }

    &-dates {
      color: $gray-700 !important;
    }

    .card {
      border: 0;

      .card-body {
        padding: 0.46875 * $font-size-base 0;
      }

      h5 {
        font-weight: 700;
        font-size: $font-size-base * 1.05;
      }

      .iso__price__value {
        font-weight: 700;
        font-size: $font-size-base * 0.95;
      }
    }
  }

  .iso__flight-summary-card-badge > span {
    color: $orange;
  }

  .iso__flight-summary-card-badge-icon {
    color: $orange;
  }

  .iso__accommodation-board-summary-card-guest-icon-active {
    color: $mediumblue;
  }

  .iso__accommodation-board-summary-card-utensil-icon {
    color: $mediumblue;
  }

  .btn.iso__accommodation-board-summary-card-button {
    border-radius: 0;
  }

  .iso__booking-note-box {
    background-color: $white;
    box-shadow: $defaultShadow;
    margin-bottom: 0.9375 * $font-size-base;
    padding: 0.625 * $font-size-base;
  }

  .iso__booking-note-button {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.625 * $font-size-base;
  }

  .iso__booking-note-button-prev {
    border-width: 2px;
    color: $primary;
    border-color: $primary;
    border-radius: 6px;
    padding-left: 0.9375 * $font-size-base;
    padding-right: 0.9375 * $font-size-base;
  }

  .iso__booking-note-button-next {
    color: $primary;
    border-color: $primary;
    border-radius: 6px;
    background-color: $primary !important;
    padding-left: 0.46875 * $font-size-base;
    padding-right: 0.46875 * $font-size-base;
    margin-left: 0.3125 * $font-size-base;
  }

  .iso__booking-note-text {
    color: $black;
  }

  .iso__booking-note-text-error {
    color: $red;
  }

  .iso__booking-note-title {
    font-weight: bold;
    margin-bottom: 0.171875 * $font-size-base;
    font-size: $font-size-base * 1.1;
  }

  .iso__booking-note-note {
    margin-bottom: 0.625 * $font-size-base;
  }

  .iso__booking-note-label {
    color: $black;
    position: relative;
    width: 100%;

    & > label {
      width: 100%;
    }
  }

  .iso__booking-note-input {
    z-index: 2;
    cursor: pointer;

    & label {
      padding-left: 20px !important;
    }
  }

  .iso__booking-note-text-error-container {
    text-align: right;
    margin-top: 0.375 * $font-size-base;
    color: $red;

    & > p {
      display: inline-block;
      margin-left: 0.21875 * $font-size-base;
    }
  }

  .iso__travelerAssignment {
    .mapping-header {
      font-size: $font-size-base;
      font-weight: 700;
      margin-bottom: 0.625 * $font-size-base;
    }

    .form-check {
      padding-left: 0;
    }

    hr {
      display: none;
    }

    form {
      display: flex;
      margin: 0 -0.3125 * $font-size-base;
      flex-wrap: wrap;

      > div {
        padding: 0 0.3125 * $font-size-base 0.625 * $font-size-base;
        flex: 0 0 100%;
        max-width: 100%;

        @include medium {
          flex: 0 0 50%;
          max-width: 50%;
        }

        @include large {
          flex: 0 0 (100% / 3);
          max-width: (100% / 3);
        }

        > div {
          box-shadow: $defaultShadow;
          padding: 0 0.3125 * $font-size-base 0.125 * $font-size-base;
        }
      }

      label.custom-control-label {
        font-weight: 600;
        padding-left: $font-size-base;
      }
    }
  }

  .traveler-assignment {
    font-weight: 700;
    font-size: $font-size-base;
    padding: 0.3125 * $font-size-base;
    margin: 0 -0.3125 * $font-size-base;
    margin-bottom: 0.375 * $font-size-base;
    border-bottom: 1px solid $gray-400;

    + div > .mapping-header {
      margin-bottom: 0;

      .info {
        font-size: $font-size-base;
        font-weight: 600;
        margin-left: -5px;
      }
    }

    > div:first-child {
      color: $mediumblue;
    }
  }

  .iso__accommodation-board-summary-card-button {
    color: $secondary;
  }

  .accommodation-adapt-overlay {
    font-size: 80%;

    .modal-body {
      padding: 0;
    }

    .modal-content {
      border-radius: 0;
      box-shadow: $defaultShadow;
      border: 0;
      min-height: 100vh;
      @include medium {
        min-height: auto;
      }
    }

    .iso__summary-header {
      border-bottom: 1px solid $lightblue;
      margin-bottom: 0.3125 * $font-size-base;
      @include medium {
        padding-left: 0.9375 * $font-size-base;
        padding-right: 0.9375 * $font-size-base;
      }
    }

    // styles for input
    .iso__input-plus-minus {
      max-width: 5.3125 * $font-size-base * $accommodation-adapt-overlay-size;
      padding-left: $font-size-base * 1.6 * $accommodation-adapt-overlay-size;
      padding-right: $font-size-base * 1.6 * $accommodation-adapt-overlay-size;
    }

    .iso__input-plus-minus-button {
      width: $font-size-base * 1.55 * $accommodation-adapt-overlay-size;
      height: $font-size-base * 1.55 * $accommodation-adapt-overlay-size;

      border: 2px solid $orange !important;
      border-radius: 50% !important;
    }

    .iso__input-plus-minus-button:focus {
      border: 2px solid $orange;
    }

    .iso__input-plus-minus-default-icon {
      color: $orange;
      font-size: $font-size-base * 3.125 * $accommodation-adapt-overlay-size;
      margin-bottom: 0.375 * $font-size-base !important;
      font-weight: 500;
      left: 0 !important;
    }

    .iso__summary-header-title {
      font-weight: 600;
    }

    .iso__summary-header-title {
      font-size: $font-size-base * 1.75 * $accommodation-adapt-overlay-size;
      font-size: 30px;
    }

    .modal-dialog {
      width: 100%;
      max-width: 1200px * $accommodation-adapt-overlay-size;
      opacity: 1;
      min-height: 100%;
      margin: auto;
      @include medium {
        width: calc(100% - 1.25rem);
      }
    }

    bottom: 0;

    .custom-control {
      padding-left: 0.625 * $font-size-base;
    }
  }

  .accommodation-adapt-overlay-backdrop {
    background-color: $blue;
  }

  .iso__accommodation-adapt-footer {
    background-color: $white;
    font-size: 120%;

    & > button:first-of-type {
      border: 2px solid $primary;
      color: $secondary;
    }
  }

  .iso__accommodation-adapt-rooms-container-bar:after {
    background-color: #dadada;
  }

  .iso__accommodation-adapt-footer-button {
    color: $secondary;
  }

  .iso__accommodation-adapt-rooms-options {
    margin-bottom: 0.75 * $font-size-base;

    span {
      color: $orange;
    }

    &.custom-radio.custom-control {
      left: 9px;
    }

    .iso__accommodation-adapt-rooms-options__label {
      display: flex;
      justify-content: space-between;
      padding-right: 0;
      align-items: center;

      > p {
        margin-bottom: 0;
        padding-right: 8px;
      }

      span {
        position: relative;
        font-size: 1.125 * $font-size-base;
        right: 0;
      }

      > span {
        flex: 1 0 auto;
        text-align: right;
      }
    }
  }

  .iso__accommodation-modal-adapt-plus-icon {
    color: $orange;
  }

  .accommodation-adapt-overlay-error-p {
    color: $white;
    background-color: $red;
    margin: 0 $font-size-base $font-size-base;
    font-size: $font-size-base * 0.95;
    padding: $font-size-base * 0.65 $font-size-base;
    text-align: left;
    border-radius: 4px;
  }

  .accommodation-adapt-overlay-error {
    padding: 1.875 * $font-size-base 1.25 * $font-size-base 1.25 * $font-size-base;

    & > .accommodation-adapt-overlay-error-p {
      text-align: center;
      font-size: $font-size-base * 1.25;
    }
  }

  .iso__accommodation-adapt-rooms-icon {
    display: inline-block;
    margin-right: 0.40625 * $font-size-base;
    margin-top: 0.3em;

    & > svg {
      color: $orange;
      display: inline;
    }
  }

  .custom-checkbox {
    .custom-control-label {
      cursor: pointer;

      &::before,
      &::after {
        top: 0.2375 * $font-size-base;
        box-shadow: none;
      }

      &::before {
        border: 2px solid $secondary;
      }

      &:hover {
        &::before {
          border-color: $darkblue;
        }
      }
    }

    .custom-control-input:focus ~ .custom-control-label {
      &::before,
      &::after {
        box-shadow: none;
      }

      &::before {
        border: 2px solid $secondary;
      }
    }

    .custom-control-input:checked ~ .custom-control-label {
      &:hover::before {
        background-color: $darkblue;
      }
    }
  }

  .iso__summary-accommodation-board-lists {
    .col-2 {
      padding: 0;
      @include small {
        padding-right: 0.3125 * $font-size-base;
        padding-left: 0.3125 * $font-size-base;
      }
    }
  }

  .iso__summary-header-box {
    font-size: $font-size-base * 0.95;
    @include medium {
      font-size: $font-size-base;
    }
  }

  .iso__input-plus-minus-input {
    font-size: $font-size-base * 1.85 * $accommodation-adapt-overlay-size;
  }

  .summary__price {
    .iso__price__value {
      white-space: nowrap;
    }
  }

  .iso__input-plus-minus-button {
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child > span {
      top: -2px !important;
    }

    &:nth-child(2) > span {
      margin-top: 3px;
    }

    .iso__input-plus-minus-default-icon {
      margin-top: 1px;
    }

    &.iso__input-plus-minus-button-left {
      .iso__input-plus-minus-default-icon {
        position: relative;
        top: -2px;
      }
    }
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }

  .iso__accommodation-adapt-input-plus {
    @include medium {
      position: relative;
      top: -6px;
    }
  }

  .iso__three-column-list-item-layout {
    &__info-inner__bottom-right-container {
      display: none;
    }

    &__content__headline {
      font-size: 1.171875 * $font-size-base;
      font-weight: 600;
    }
  }

  .fade {
    opacity: 1;
  }

  .flex-column {
    display: initial;
    flex-direction: column;
    flex-wrap: initial;
    justify-content: initial;
    align-content: initial;
    align-items: initial;

    > * {
      flex: initial;
      align-self: initial;
    }
  }

  .iso__summary-header-separator {
    background-color: $primary;
    height: $font-size-base * 0.9;
  }

  .iso__checkout__paymentOption {
    & label {
      padding-left: 17px !important;
    }
  }

  .iso__service-list__sorter {
    &__container {
      & > button {
        border: 2px solid #b1b2b3;
        color: $blue;
        background-color: $white;
        border-radius: 5px !important;
        margin-right: 5px;
      }

      & > button:last-child {
        margin-right: 0;
      }

      margin-bottom: $font-size-base * 0.08;
      margin-top: $font-size-base * 1.875;
    }

    &__container > .btn {
      & > span {
        border-radius: 5px;
      }
    }

    &__button {
      border: 2px solid $orange;
      font-weight: 600;
      min-width: $font-size-base * 6;
      padding: 0.18125 * $font-size-base 0.46875 * $font-size-base;
      display: flex;
      justify-content: center;
      align-items: center;

      @include small {
        min-width: $font-size-base * 8;
      }
      outline: none;
      box-shadow: none;

      & > span {
        position: absolute;
        z-index: 1;
        border: 2.5px solid $primary;
        background-color: transparent;
        left: -2px;
        right: -2px;
        bottom: -2px;
        top: -2px;
        display: none;
      }

      &--active {
        position: relative;
        font-weight: 700;

        & > span {
          display: block !important;
          border-color: $primary !important;
        }
      }

      &:hover {
        color: $blue;

        & > span {
          display: block;
          border-color: $orange;
          z-index: 2;
        }
      }

      &:focus {
        box-shadow: none;
      }
    }

    &__icon {
      margin-left: 0.40625 * $font-size-base;
      margin-top: 1.5px;
      font-size: $font-size-base * 0.91;
    }
  }

  .package-list-item__center {
    .center-lower > div {
      display: flex;
      flex-direction: column;

      @include small {
        flex-direction: row;
      }
    }
  }

  .bestprice-details {
    .picture-gallery {
      margin-top: 0.9375 * $font-size-base;
    }

    .package-info__details .package-info__data {
      font-size: $font-size-base;
    }
  }

  .package-list-item__center {
    .center-lower > div {
      display: flex;
      flex-direction: column;

      @include small {
        flex-direction: row;
      }
    }
  }

  .bestprice-details {
    .picture-gallery {
      margin-top: 0.9375 * $font-size-base;
    }

    .package-info__details .package-info__data {
      font-size: $font-size-base;
    }
  }

  .modal.fade .modal-dialog {
    transform: translate(0);
  }

  .iso__accommodation-board-summary-card {
    font-size: $font-size-base;
  }

  .iso__flight-summary-card {
    font-size: $font-size-base;
  }

  .custom-summary-flight-date-display > div > div {
    min-width: $font-size-base * 4.5;
    width: inherit;
  }

  .custom-summary-flight-date-display > div:last-child > div {
    text-align: center;
  }

  .iso__modal-header--sticky {
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 1055;
    @include medium {
      position: relative;
      z-index: 1;
      top: inherit;
    }
  }

  .iso__modal-footer--sticky {
    position: sticky;
    bottom: 0;
    background-color: $white;
    z-index: 1055;
    @include medium {
      position: relative;
      z-index: 1;
      bottom: inherit;
    }
  }

  .iso__modal-footer--sticky .iso__accommodation-adapt-footer {
    position: relative;
  }

  .iso__modal-footer--sticky .iso__accommodation-adapt-footer-spacing {
    height: 0;
    @include medium {
      height: 2.8125 * $font-size-base;
    }
  }

  .iso__accommodation-adapt-footer-spacing--custom {
    height: 4.5em;
  }

  .iso__accommodation-adapt-wrapper {
    min-height: calc(100vh - 150px);
    @include medium {
      min-height: inherit;
    }
  }

  .iso__filterRange__values {
    font-size: 14px;
  }

  .btn-rounded {
    border-radius: 5px !important;
  }

  .iso__accommodation-board-summary-card-button {
    border-radius: 0 !important;
  }

  .iso__accommodation-board-summary-card--ibe-flight {
    .iso__accommodation-board-summary-card-button.iso__accommodation-board-summary-card-button-bottom {
      left: 0.625 * $font-size-base;
      width: calc(100% - 1.25rem);
      bottom: 0.625 * $font-size-base;
      border-radius: 5px !important;
    }
  }

  .iso__overlay__backdrop,
  .iso__overlay__spinner__container {
    z-index: 20;
  }

  .product-ibe .animated_spinner_container {
    .iso__overlay__backdrop {
      width: 100% !important;
      height: 100% !important;
      position: fixed !important;
    }

    .iso__overlay__spinner__container {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      max-width: 900px;
      max-height: 506px;
      width: 100%;
      object-fit: contain;
    }
  }

  .geounit-checkbox__container {
    overflow: auto;
    max-height: calc(100vh - 80px);
  }

  .search__collapsible__sticky .geounit-checkbox__container {
    overflow: auto;
    max-height: calc(100vh - 160px);
    @media (max-height: 820px) {
      padding-bottom: 0;
      & > div:last-child {
        position: sticky;
        bottom: 0;
        background-color: $white;
        z-index: 2;
        padding-bottom: 10px;
        padding-top: 7px;
        border-top: 1px solid $gray-200;
        padding-left: 14px;
        margin-left: -12px;
        margin-right: -12px;
        padding-right: 14px;
        @include small {
          position: inherit;
          bottom: 0;
          padding: 0;
          border-top: 0;
          margin: 0;
        }
      }
    }
  }

  .productSelect-item {
    padding: 8px 10px;
    cursor: pointer;
    padding-left: 25px;

    & > div {
      display: none;
    }
  }

  .productSelect-country {
    position: relative;
    font-weight: bold;
    padding-left: 10px;
    margin-top: 10px;

    & > div {
      display: block;
      height: 2px;
      left: 10px;
      right: 10px;
      background: $gray-400;
      top: -5px;
      z-index: 2;
      position: absolute;
    }
  }

  .productSelect-item:hover {
    background-color: $secondary;
    color: $white;
  }

  .iso__custom__product__select {
    font-size: $font-size-sm;
  }

  .iso__custom__product__select__lists > div:first-child .productSelect-country {
    margin: 0;

    & > div {
      display: none;
    }
  }

  .iso__custom__product__select__lists > div:first-child .productSelect-label {
    border-width: 0;
    margin-top: 0;

    & > span {
      display: none;
    }
  }

  .iso__custom__product__select__dropdown__selection__btn {
    & button {
      font-size: $font-size-sm;
      padding: 0 15px;
    }

    & > button:first-child {
      padding: 0;
    }
  }

  .iso__custom__product__select__dropdown__selection__item {
    padding: $font-size-base * 0.9;
  }

  .productSelect-label {
    margin-top: 10px;
    font-weight: bold;
    padding: 5px 10px;
    position: relative;

    & > span {
      display: block;
      height: 2px;
      left: 10px;
      right: 10px;
      background: $gray-400;
      top: -5px;
      z-index: 2;
      position: absolute;
    }
  }

  .productSelect-item---search {
    padding: 7px 10px;
    cursor: pointer;

    &:hover {
      background-color: $secondary;
      color: $white;
    }
  }

  .iso__custom__product__select__dropdown {
    max-height: 400px;
    left: -4px;
    right: -4px;
    top: 5px;
  }

  .iso__custom__product__select__lists--empty {
    padding: 10px;
  }

  .iso__modal-input-wrapper {
    margin: 0;
    max-width: 960px;
    min-height: 100%;
    opacity: 1;
    width: 100%;

    & .modal-content {
      border: 0 !important;
      border-radius: 0.1875 * $font-size-base !important;
      min-height: 100vh;
    }

    & .modal-body {
      padding: 0.625 * $font-size-base 1.25 * $font-size-base;
    }

    &--backdrop {
      opacity: 1 !important;
      background: white;
    }

    &--close {
      display: flex;
      justify-content: flex-end;
      position: relative;
      margin-top: $font-size-base;
      margin-bottom: $font-size-base * 1.5;

      & > svg {
        font-size: $font-size-base * 1.5;
      }
    }

    & .iso__custom__product__select__dropdown {
      box-shadow: none;
    }

    & .productSelect-item---search,
    & .productSelect-label {
      padding: 5px 0;
    }

    & .productSelect-item {
      padding-left: 15px;
    }

    & .productSelect-country {
      padding-left: 0;

      & > div {
        left: 0;
        right: 0;
      }
    }

    & .productSelect-label > span {
      left: 0;
      right: 0;
    }

    .productSelect-item:hover {
      background-color: $white;
      color: $black;
    }

    .productSelect-item---search {
      &:hover {
        background-color: $white;
        color: $black;
      }
    }

    & input {
      padding-left: 0;
    }

    & .iso__custom__product__select__input {
      & > svg {
        display: none;
      }

      & > span {
        display: block;
      }
    }

    & .iso__custom__product__select__dropdown {
      max-height: calc(100vh - 160px);
    }
  }

  @media (max-width: 576px) {
    .search-collapsible__outer .search-collapsible {
      flex-direction: row;

      & button {
        margin: 0 !important;
      }

      & .package__search__content {
        & > div {
          font-size: 12px;
          margin: 5px 5px 5px 0;
        }

        & > div:last-child {
          display: none;
        }
      }
    }
  }

  .iso__date-range-input_duration {
    margin: 0 !important;
  }

  .iso__dateRangeInput__customContainer__input__container {
    .select__dropdown-indicator > svg {
      color: $secondary;
    }
  }

  @media (max-width: 675px) {
    .iso__dateRangeInput__customContainer__calendar__label {
      font-size: 16px !important;
    }
    .iso__dateRangeInput__customContainer__duration__label {
      font-size: 16px !important;
    }
    .iso__date-range-input {
      padding-right: 0;

      & > div > div {
        > span {
          margin: 0 1.25px !important;
        }
      }
    }
  }

  .modal {
    overflow: auto;

    &-dialog {
      &--goldenRatio {
        max-width: 770px !important;

        .carousel-item img {
          @include medium {
            max-width: 770px;
          }
        }
      }
    }
  }

  .checkout-nav {
    button,
    .btn.btn-primary,
    .btn.btn-secondary {
      font-size: 20px;
    }
  }

  @media (min-width: 1200px) {
    .best-price__list-selections__container {
      box-shadow: $defaultShadow !important;
      border: 1px solid #e4e7ec !important;
      padding: 0.625 * $font-size-base !important;
    }
  }

  .best-price__availability__list {
    .service-list__container {
      .service-list__item {
        @media (max-width: 1199px) {
          border-radius: 0.15625 * $font-size-base;
          box-shadow: $defaultShadow;
          overflow: hidden;
          border: 1px solid $gray-400;
        }
      }
    }
  }

  .simple-list {
    &__warning {
      background-color: transparent !important;
      width: 100%;

      td {
        padding: 0;
      }
    }

    [name^='list-item-unavailable'] {
      text-align: left;
    }
  }

  .summary__collapsible__header {
    padding-top: 4px;
    padding-bottom: 4px;
    color: $mediumblue;

    & svg {
      font-size: 14px;
    }

    & .summary__traveler {
      margin-left: 10px;
    }

    &--open {
      color: $darkerBlack;
    }
  }

  .checkout_main_container--shadow {
    box-shadow: 0 0 8px #d7d7d7;
  }

  .travellerData {
    box-shadow: none;
  }

  .iso__flight-and-accommodation {
    @include medium {
      > .col-md-auto:last-of-type {
        flex: 1;
      }
    }
  }

  .iso__flight-and-accommodation__header {
    @include medium {
      padding: 0.25rem 1rem;
    }
    @include large {
      padding: 0.25rem 2rem;
    }

    &__box {
      font-weight: 600;
    }

    > h2 {
      font-size: 1.6875 * $font-size-base;
      @include medium {
        font-size: 1.25 * $font-size-base;
      }
      @include large {
        font-size: 1.6875 * $font-size-base;
      }
    }
  }

  .summary__group {
    &__header {
      font-weight: bold;
      color: $darkerBlack;
    }

    &--discount {
      color: $mediumblue !important;

      & .summary__group__header {
        color: $mediumblue;
      }
    }

    &__lists {
      & > div:last-child {
        margin-bottom: 0.3125 * $font-size-base;
      }
    }
  }

  .summary__traveler {
    &__price {
      white-space: nowrap;
      font-weight: bold;
      color: $red;
    }

    &__info {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @import 'flight.scss';
  @import 'extendedBooking.scss';
}

body.modal-open {
  overflow: hidden;
}

.animated-spinner-container {
  width: 100vw !important;
  height: 100vh !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed !important;
  background-color: #004f9f !important;
  opacity: 0.75 !important;

  .animated-spinner {
    width: 100% !important;
    height: 100% !important;
    position: fixed !important;
    max-width: 900px;
    max-height: 506px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
  }
}
